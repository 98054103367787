.lang-button {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    outline: 0;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    background: none;
    line-height: 1;
}
.lang-button.active {
    border-color: var(--antd-wave-shadow-color);
}
.lang-button img {
    /*width: 100%;*/
    height: 100%;
    object-fit: cover;
}